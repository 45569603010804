import request from '../Axios'

// 新增
export const homeAdd = data => {
  return request({
    url: '/home',
    method: 'post',
    data
  })
}

// 修改
export const homeEdit = data => {
  return request({
    url: `/home`,
    method: 'put',
    data
  })
}

// 查询
export const homeList = params => {
  return request({
    url: '/home',
    method: 'get',
    params
  })
}

// 路径查询
export const homeJumpUrl = params => {
  return request({
    url: '/dict/queryList/homeJumpUrl',
    method: 'get',
    params
  })
}

// 删除
export const homeDelete = params => {
  return request({
    url: `/home/${params.id}`,
    method: 'delete'
  })
}
