<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="addItem">新增大图</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #backgroundUrl>
        <el-table-column label="大图内容" width="230" align="center">
          <template #default="scoped">
            <img
              :src="scoped.row.backgroundUrl"
              class="backgroundUrl"
              @click="openImagePreview(scoped.row)"
            />
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="editItem(scoped.row)"
              v-if="scoped.row.active == 0"
            >
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="detailsItem(scoped.row)"
              v-if="scoped.row.active != 0"
            >
              详情
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="upItem(scoped.row)"
              v-if="scoped.row.active == 0"
            >
              上架大图
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="downItem(scoped.row)"
              v-if="scoped.row.active != 0"
            >
              下架大图
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="deleteItem(scoped.row)"
              v-if="scoped.row.active == 0"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="labelVisible"
      :title="title"
      width="50%"
      class="user_dialog"
      :before-close="labelClose"
    >
      <div class="box_dialog" v-if="title == '详情'"></div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="大图名称：" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入大图名称"
            :maxlength="8"
          />
        </el-form-item>
        <el-form-item label="副标题：" prop="subTitle">
          <el-input v-model="ruleForm.subTitle" placeholder="请输入副标题" />
        </el-form-item>
        <el-form-item label="按钮文案：" prop="btntext">
          <el-input
            v-model="ruleForm.btntext"
            placeholder="请输入按钮文案"
            :maxlength="6"
          />
        </el-form-item>
        <el-form-item label="按钮颜色：" prop="btnColor" class="colorcont">
          <el-input
            v-model="ruleForm.btnColor"
            placeholder="请输入按钮颜色"
            :maxlength="7"
            disabled
          />
          <el-color-picker v-model="ruleForm.btnColor" />
        </el-form-item>
        <el-form-item label="按钮样式：" prop="btnStyle">
          <el-radio-group v-model="ruleForm.btnStyle">
            <el-radio :label="0" size="large">圆角按钮</el-radio>
            <el-radio :label="1" size="large">纯文字按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="跳转路径：">
          <el-select
            v-model="ruleForm.url"
            filterable
            allow-create
            clearable
            placeholder="请输入或者选择路径"
          >
            <el-option
              v-for="item in homeJumpUrlList"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            />
          </el-select>
          <el-text type="primary" class="tip">
            自定义填写路径需确保路径有效性
          </el-text>
        </el-form-item>
        <el-form-item label="显示排序：" prop="sort">
          <el-input
            v-model="ruleForm.sort"
            placeholder="请输入排序"
            @keyup="numberInput"
          />
        </el-form-item>
        <el-form-item label="上传大图：" prop="pic">
          <div class="upload">
            <fileUpload
              v-if="labelVisible"
              @getimglist="getimglist"
              ref="uploadFilesDom"
              widthSize="1125"
              heightSize="2436"
              :imgUrl="ruleForm.pic"
            />
          </div>
        </el-form-item>
      </el-form>
      <template #footer v-if="title != '详情'">
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-image-viewer
      v-if="goodsImageList.length"
      :url-list="goodsImageList"
      hide-on-click-modal
      @close="handleClose"
    />
  </div>
</template>

<script setup>
import fileUpload from '@/components/fileUpload1'
import { reactive, onMounted, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  homeAdd,
  homeEdit,
  homeList,
  homeDelete,
  homeJumpUrl
} from '@/api/getUser/homeManagement'
const ruleFormRef = ref()

const validateUrl = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入或者选择路径'))
  } else {
    if (value.indexOf('pages') != 0) {
      callback(new Error('请以/pages为开头'))
    }
    callback()
  }
}

const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入关键字以搜索'
    }
  ],
  fromData: {
    // 表单数据
    keywords: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'title', label: '大图标题' },
    { prop: 'subTitle', label: '副标题' },
    { slot: 'backgroundUrl' },
    { prop: 'button', label: '按钮文案' },
    { prop: 'sort', label: '排序' },
    { prop: 'jumpUrl', label: '跳转页面/路径' },
    { prop: 'activeName', label: '上架状态' },
    { slot: 'action' }
  ],
  ruleForm: {
    id: '',
    name: '',
    subTitle: '',
    btntext: '',
    btnColor: '',
    btnStyle: 0,
    url: '',
    sort: '',
    pic: ''
  },
  rules: {
    name: [
      {
        required: true,
        message: '请输入大图名称',
        trigger: 'blur'
      },
      { min: 1, max: 8, message: '请输入8个字符以内的名称', trigger: 'blur' }
    ],
    subTitle: [
      {
        required: true,
        message: '请输入副标题',
        trigger: 'blur'
      }
    ],
    btntext: [
      {
        required: true,
        message: '请输入按钮文案',
        trigger: 'blur'
      },
      { min: 1, max: 6, message: '请输入6个字符以内的文案', trigger: 'blur' }
    ],
    btnColor: [
      {
        required: true,
        message: '请输入按钮颜色',
        trigger: 'blur'
      }
    ],
    btnStyle: [
      {
        required: true,
        message: '请选择按钮样式',
        trigger: 'change'
      }
    ],
    // url: [{ validator: validateUrl, trigger: 'change' }],
    sort: [
      {
        required: true,
        message: '请输入排序',
        trigger: 'blur'
      }
    ],
    pic: [
      {
        required: true,
        message: '请上传大图'
      }
    ]
  },
  labelVisible: false,
  title: '',
  goodsImageList: [],
  homeJumpUrlList: []
})

onMounted(() => {
  getDataList()
  getHomeJumpUrl()
})

// 预览图片
const handleClose = () => {
  goodsImageList.value = []
}

// 图片预览
const openImagePreview = row => {
  goodsImageList.value = [row.backgroundUrl]
}

// 排序限制
const numberInput = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}

const getHomeJumpUrl = () => {
  homeJumpUrl().then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      homeJumpUrlList.value = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 修改状态
const editItem = row => {
  title.value = '修改'
  ruleForm.value = {
    id: row.id,
    name: row.title,
    subTitle: row.subTitle,
    btntext: row.button,
    btnStyle: row.btnStyle,
    btnColor: row.btnColor,
    url: row.jumpUrl,
    sort: row.sort,
    pic: row.backgroundUrl
  }
  labelVisible.value = true
}

// 详情
const detailsItem = row => {
  title.value = '详情'
  ruleForm.value = {
    id: row.id,
    name: row.title,
    subTitle: row.subTitle,
    btntext: row.button,
    btnStyle: row.btnStyle,
    btnColor: row.btnColor,
    url: row.jumpUrl,
    sort: row.sort,
    pic: row.backgroundUrl
  }
  labelVisible.value = true
}

// 新增
const addItem = () => {
  title.value = '新增'
  ruleForm.value = {
    id: '',
    name: '',
    subTitle: '',
    btntext: '',
    btnColor: '',
    btnStyle: 0,
    url: '',
    sort: '',
    pic: ''
  }
  labelVisible.value = true
}

// 关闭
const labelClose = () => {
  ruleForm.value = {
    id: '',
    name: '',
    subTitle: '',
    btntext: '',
    btnColor: '',
    btnStyle: 0,
    url: '',
    sort: '',
    pic: ''
  }
  ruleFormRef.value.resetFields()
  labelVisible.value = false
}

const inputcolor = val => {
  if (val.slice(0, 1) != '#') {
    ElMessage.error('请以#开头')
  }
}

// 图片上传
const getimglist = val => {
  ruleForm.value.pic = val
}

// 删除
const deleteItem = row => {
  ElMessageBox.confirm('是否确认删除（该操作不可逆），是否继续？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      homeDelete({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 上架
const upItem = row => {
  ElMessageBox.confirm(
    '上架大图后，该大图将在小程序展示。是否继续？',
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      const data = {
        title: row.title,
        subTitle: row.subTitle,
        backgroundUrl: row.backgroundUrl,
        button: row.button,
        btnStyle: row.btnStyle,
        btnColor: row.btnColor,
        sort: Number(row.sort),
        jumpUrl: row.jumpUrl,
        active: 1,
        id: row.id
      }
      homeEdit(data).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 下架
const downItem = row => {
  ElMessageBox.confirm(
    '上架大图后，该大图将在小程序中不可见。是否继续？',
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      const data = {
        title: row.title,
        subTitle: row.subTitle,
        backgroundUrl: row.backgroundUrl,
        button: row.button,
        btnStyle: row.btnStyle,
        btnColor: row.btnColor,
        sort: Number(row.sort),
        jumpUrl: row.jumpUrl,
        active: 0,
        id: row.id
      }
      homeEdit(data).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 提交
const remarkSubmit = async () => {
  if (!ruleFormRef.value) return
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      const data = {
        title: ruleForm.value.name,
        subTitle: ruleForm.value.subTitle,
        backgroundUrl: ruleForm.value.pic,
        button: ruleForm.value.btntext,
        btnStyle: ruleForm.value.btnStyle,
        btnColor: ruleForm.value.btnColor,
        sort: Number(ruleForm.value.sort),
        jumpUrl: ruleForm.value.url
      }
      if (ruleForm.value.id == '') {
        homeAdd(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            getDataList()
            labelVisible.value = false
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        data.id = ruleForm.value.id
        homeEdit(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            getDataList()
            labelVisible.value = false
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords != '') {
    data.keyword = fromData.value.keywords
  }
  homeList(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  ruleForm,
  rules,
  labelVisible,
  title,
  goodsImageList,
  homeJumpUrlList
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  span {
    width: 100px;
    text-align: right;
  }

  .dialog_input {
    width: 50%;
  }

  i {
    color: red;
  }
}

.remark_box {
  align-items: flex-start;
}
.demo-ruleForm {
  width: 50%;
  margin-left: 60px;
  .el-form-item__content {
    width: calc(100% - 100px);
    position: relative;
  }
  .el-input,
  .el-select {
    width: 100%;
  }
}
.tip {
  display: block;
  width: 220px;
  position: absolute;
  top: 5px;
  right: -220px;
}
.upload {
  width: 100%;
}

.backgroundUrl {
  width: 100px;
}
.box_dialog {
  width: 100%;
  height: calc(100% - 60px);
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
}
:deep(.colorcont) {
  position: relative;
  .el-color-picker {
    position: absolute;
    top: 0;
    right: 0;
  }
  .el-input.is-disabled .el-input__wrapper {
    background: transparent;
    // .el-input__inner {
    //   color: #606266;
    //   -webkit-text-fill-color: #606266;
    // }
  }
}
</style>
